@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  padding-right: 1px !important;
  overflow-y: scroll !important;
}



@font-face {
  font-family: 'CiutadellaLight';
  src: url('../public/fonts/ciutadella/Ciutadella\ Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
 
@font-face {
  font-family: 'CiutadellaMedium';
  src: url('../public/fonts/ciutadella/Ciutadella\ Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CiutadellaRegular';
  src: url('../public/fonts/ciutadella/Ciutadella\ Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CiutadellaSemibold';
  src: url('../public/fonts/ciutadella/Ciutadella\ SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CiutadellaBold';
  src: url('../public/fonts/ciutadella/Ciutadella\ Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


:root {
  --font-trial: 'future';
  --font-regular: "CiutadellaRegular";
  --font-light: 'CiutadellaLight';
  --font-medium: 'CiutadellaMedium';
  --font-semibold: "CiutadellaSemibold";
  --font-bold: "CiutadellaBold";
  
  --main-color: "#22356D";
  --lesson-color: "#9ca5be";
  --unfilled-open: "#EAF956";
  --no-show: "#AD00FF";
  --pending-payment: '#FF0000';
  --lessons-color: "#E5E5E5";
  --event-color: "#8d98b4";
  --events-paper: '#FDF0E6';
  --open-match: '#EBECFD';
  --lessons-paper: '#FFFDD1';
  --font-color1: "#7E93FF";
  --font-color2: "#5B8777";
  --font-color3: "#A3A434";
  --font-color4: '#F07727';
  --custom-fields: 100% !important;
}

.masked-input-container {
  position: relative;
  font-family: sans-serif;
}

.masked-input-wrapper {
  position: relative;
  display: inline-block;
}

input[type="text"] {
  font-family: sans-serif;
  padding: 5px;
  font-size: 16px;
}

.sb-chat-btn{
  background-color: #22356D !important;
}
.sb-header{
  background-color: #22356D !important;
}

.eventSelector {
  color: #22356D !important;
}

input.masked {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 16px;
  color: #000;
  pointer-events: none;
  background-color: white;
  width: 100%;
  margin-left: 2px;
}


input[type="password"] {
  -webkit-text-security: disc;
  /* text-security: disc; */
}

input{
  font-family: var(--font-medium) !important;
  padding-left: 12px;
  outline: none !important;
  border-radius: 7px;
  /* border: 1px solid #EEEFEE; */
}

input::placeholder {
  font-family: var(--font-regular) !important;
  text-align: left;
  color:#22356D99 !important;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #22356D99 !important;
}

.placeholder>input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #22356D99 !important;
}

.placeholders>input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #22356D !important;
}

.css-n7pfm1-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: #889AB61A !important;

}

.css-n7pfm1-MuiButtonBase-root-MuiPaginationItem-root{
  color:#22356DB2 !important;
  border: 1px solid #889AB61A !important;
  font-family: var(--font-medium) !important;
}

fieldset{
  border: none !important;
}

.bg-color{
  background-color: #889AB626;
}

.borderRight>div:last-child{
  border-right:none !important
}

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #22356D;
  border-radius: 50%;
  margin-right: 8px;
  display: block;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.rbc-time-header {
  margin-bottom: -20px;
}
.rbc-time-content{
  padding-top: 20px;
}
.rbc-time-gutter>.rbc-timeslot-group {
  margin-top: -10px !important;
}

.container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #22356D;
}

textarea:focus, input:focus{
   border: none; 
outline: none;
}

/* #air-datepicker-global-container>div:nth-child(2){
  display: none !important;
} */

.MobileHamburger{
  display: none !important;
 }

  .show-row{
    display: table-cell;
  }

  .booking-row{
    display: inherit;
  }

  .fontSize{
    font-size: 1rem !important;
  }

  .show-divider{
    display:block;
  }

@media screen and (max-width: 600px){
  :root { 
    --custom-fields: 81% !important;
  }
  .show-row{
    display: none !important;
  }
  .booking-row{
    display:none !important;
  }
  .fontSize{
    font-size: 0.85rem !important;
  }
  .headingasdfsdf{
    text-align: left !important;
  }
  .show-divider{
    display:none !important;
  }
}

.filter > img{
  width:18px !important;
  height:18px !important;
}

.NotificationPopover .MuiPaper-elevation{
  border-radius: 12px !important;
}

@media  (max-width: 900px)  {
  .headingasdfsdf{
    text-align: left !important;
  }
  .filter > img{
    width:18px !important;
    height:18px !important;
  }
  .mobileloginscreenRight{
    position: absolute;
    border-radius: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
   }
   .mobileloginscreenRightForm{
    min-width: 280px;
   }
   .mobileloginscreenRightSubHeading{
    text-align: center;
   }
   .mobileloginscreenRightHeading{
    text-align: center;
   }
   .MobileHamburger{
    color: #22356D;
    display: block !important;
    font-size: 1.3rem ;
   }
}
