.custom-dropdown {
  position: relative;
  background-color: white;
  padding: 5px 0px;
  font-size: 14px;
  border: 1px solid #eeefee;
  border-radius: 7px;
  color: #22356d;
  height: 28px;
  width: auto;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Others */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.arrow {
  margin-left: 10px;
  color: #22356d;
  opacity: 0.4;
  margin-bottom: 2px;
}

#tabSlider-search > div > button > div {
  font-family: var(--font-regular) !important;
  text-transform: lowercase;
}

.options {
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 50;
  width: auto;
  max-height: 200px;
  overflow-y: scroll;
  border-top: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.MuiTabs-scrollButtons {
  margin-top: 10px;
}

.custom-dropdown.frombookings .options {
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 50;
  width: auto;
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  border-top: none;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}

.option:hover {
  background-color: #22356db2;
  color: white;
}
.preselectedOption {
  background-color: #22356db2;
  color: white;
}

.option {
  padding: 5px 10px;
  cursor: pointer;
}

.CoachSettingsTabs .MuiTabs-indicator {
  display: none;
}

.custoomasdfasdf option:hover {
  background-color: #22356db2 !important;
  color: white !important;
  border-radius: 7px;
}
.custoomasdfasdf option {
  background-color: #f3f5f8 !important;
  color: #22356db2 !important;
  border: unset !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgba(34, 53, 109, 0.7);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgba(34, 53, 109, 0.7);
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 50%;
}

.custoomasdfasdf {
  font-size: 14px;
}

#calendar .air-datepicker.-inline- {
  width: 239px !important;
}

#calendar .air-datepicker.-inline- div {
  font-size: 16px;
  font-family: var(--font-regular) !important;
  line-height: 17px !important;
}

#calendar .air-datepicker-cell.-selected- {
  background-color: #22356d !important;
}
#calendar .air-datepicker-cell.-in-range- {
  background-color: rgba(136, 154, 182, 0.15);
}

input:disabled {
  background-color: white !important;
  pointer-events: none !important;
}

#inputDis {
  background-color: #889ab626 !important;
}

/* heading*/
.headingasdfsdf {
  color: #22356d;
  margin-top: 64px;
  padding: 12px 0;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  font-family: var(--font-semibold);
  text-align: center;
  line-height: 17px; /* 106.25% */
}

/* ------ checkbox design ----- */
.container {
  top: -9px;
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 13px;
}

.container input {
  visibility: hidden;
  cursor: pointer;
}

#customerror {
  border: 1px solid red !important;
}

.mark {
  position: absolute;
  top: 9px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eeefee;
  border: 1px solid
    var(--Main-Colors-Blue-Main-Transparency-1, rgba(34, 53, 109, 0.7));
  border-radius: 4px;
}

.container input:checked ~ .mark {
  border: 1px solid
    var(--Main-Colors-Blue-Main-Transparency-1, rgba(34, 53, 109, 0.7));
  background: var(
    --Main-Colors-Blue-Main-Transparency-1,
    rgba(34, 53, 109, 0.7)
  );
}

.container input:disabled ~ .mark {
  border: 1px solid
    var(--Main-Colors-Blue-Main-Transparency-1, rgba(34, 53, 109, 0.7));
  background: var(--Main-Colors-Blue-Main-Transparency-1, rgba(0, 0, 0, 0.2));
}

.mark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .mark:after {
  display: block;
}

.container .mark:after {
  left: 5.5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* ----- ends ----- */

.person-list {
  width: 100%;
  border-radius: 7px;
  border-collapse: separate;
  border-spacing: 0;
}

.person-list > div > div {
  padding: 15px 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.person-list > div > div:nth-child(odd),
.waitingList > div:nth-child(odd) {
  background-color: #889ab61a;
}

.customerList > div:nth-child(odd) {
  background-color: #889ab61a;
}

#table-person {
  overflow-y: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: #0000000d 3px 3px 6px 0px inset, #0000000d -3px -3px 6px 1px inset;
}

#customdropdown svg {
  opacity: 0.3;
  width: 10px;
}

.tbsssfasddasd
  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
  display: none !important;
}
.css-1q0ig8p.Mui-disabled {
  display: none !important;
}

.recur {
  margin-top: 0 !important;
}

.recur > div:nth-child(odd) {
  background-color: #889ab61a;
}

.corssiconstyle {
  cursor: pointer;
  margin-top: 5px;
  font-size: 18px;
}

.corssiconstyle :hover {
  background-color: #22356d;
  color: white;
}

.selectalldflkndfg {
  color: var(--Main-Colors-Blue-Main-Transparency-1, rgba(34, 53, 109, 0.7));
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 100% */
}

/* ----- webkit scollbar design ----- */
#table-person::-webkit-scrollbar {
  width: 14px;
}

#table-person::-webkit-scrollbar-track {
  border-radius: 0px 7px 7px 0px;
  border: 0.5px solid #eeefee;
  background: linear-gradient(
      0deg,
      rgba(238, 239, 238, 0.05) 0%,
      rgba(238, 239, 238, 0.05) 100%
    ),
    #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset,
    -4px 0px 4px 0px rgba(0, 0, 0, 0.05) inset;
}

#table-person::-webkit-scrollbar-thumb {
  border: 4px solid #eeefee;
  background: rgba(147, 147, 147, 0.4);
  border-radius: 7px;
  margin-top: 10px;
}
/* ---- ends ---- */

.addPlayer {
  border: 1px solid #eeefee;
  outline: none;
  border-radius: 7px;
  height: 37px;
  width: 184px;
  padding-left: 10px;
  color: #22356d;
  font-family: var(--font-regular) !important;
}

/* ----- recurring booking ----- */
.group[data-selected="true"]
  .group-data-\[selected\=true\]\:text-default-foreground {
  color: #22356d !important;
  font-family: var(--font-medium) !important;
}
.color {
  color: #22356d99;
}
.const-width {
  width: 78px;
  font-family: var(--font-regular) !important;
}
.rounded-small,
.rounded-medium {
  border-radius: 7px !important;
}
.dynamic-width {
  width: 91.5px;
}
.starting-date {
  margin-top: 8.5px;
  font-size: 1rem;
  color: #22356d99;
}
.every-wrapper {
  width: 21.5% !important;
  margin-left: 45% !important;
}
.month-wrapper {
  margin-left: 42% !important;
  width: 23.5% !important;
}
.starting-trigger {
  min-height: 35.5px;
  height: 35.5px;
}
.starting-date-wrapper {
  width: 41%;
  margin-left: auto;
  color: #22356d99;
  font-size: 1rem;
}
.starting-date-value {
  font-size: 0.95rem;
}
/* ends */

/* ------ air datepicker ------ */
.air-datepicker:not(.-custom-position-) {
  opacity: 1 !important;
}

.air-datepicker {
  width: auto !important;
  box-shadow: none !important;
  z-index: 9999 !important;
  font-family: var(--font-regular) !important;
  font-size: 01.2rem !important;
  position: relative !important;
  border: 1px solid #93939366 !important;
  border-radius: 7px !important;
}
.air-datepicker > input {
  width: 100%;
  padding: 3px 0px 0px 9px;
  border-radius: 7px;
  border: 1px solid #93939366;
  font-family: var(--font-regular) !important;
  outline: none;
  font-size: 0.955rem;
  height: 32px;
  color: #889ab6b2;
}
.air-datepicker > input::-webkit-input-placeholder {
  font-family: var(--font-regular) !important;
  font-weight: normal !important;
  color: #889ab6b2;
}
.air-datepicker-nav--title,
.air-datepicker-body--day-name,
.air-datepicker-body--cells {
  color: #22356db2 !important;
}
.internal-notes {
  max-height: 168px !important;
  margin-top: 6px !important;
}
/* ends */

/* ----- logs ----- */
.logs {
  margin-top: 20px;
}
.logs > p:first-child {
  color: #22356d80;
  font-size: 10px;
}

.logs > p:nth-child(2) {
  color: #22356d;
  font-size: 16px;
  font-family: var(--font-semibold);
}

.logs > p:last-child {
  font-size: 14px;
  color: #22356d;
}

/* ------- mui css overright ------- */
#customer {
  width: 60px !important;
  height: 60px !important;
}

.scroll-bar::-webkit-scrollbar {
  width: 12px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 600px) {
  .const-width {
    width: 70px;
  }
  .month-wrapper {
    margin-left: 40% !important;
  }
  .every-wrapper {
    margin-left: 43% !important;
  }
  .addPlayer {
    width: 160px !important;
  }
  .selectalldflkndfg {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 400px) {
  .gap-mobile {
    flex-wrap: wrap !important;
  }
  .customSVG::before {
    left: -7px;
  }
}
