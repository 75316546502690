.MuiMenuItem-gutters {
  font-family: var(--font-regular) !important;
}

.underlinestyle {
  text-decoration: underline;
  text-underline-offset: 5px;
}

table {
  color: #22356db2;
  font-family: var(--font-regular);
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-collapse: separate;
  border-spacing: 0;
}

table thead {
  font-family: var(--font-medium);
}

table tbody tr:first-child td {
  border-top: 1.5px solid #889ab61a;
}

tr > td {
  box-sizing: border-box;
  text-align: center;
  height: 43px;
}

tbody {
  font-size: 0.85rem;
}

table tr td:first-child {
  width: 100px;
}

table tr td:not(:first-child) {
  width: 10.8%;
  border-left: 1.5px solid transparent;
}

tbody > tr:nth-child(odd) {
  background-color: #889ab61a;
}

tbody > tr:nth-child(even) {
  background-color: white;
}

thead tr:first-child td:first-child {
  border-top-left-radius: 7px;
}
thead tr:first-child td:last-child {
  border-top-right-radius: 7px;
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: 7px;
}
tbody tr:last-child td:last-child {
  border-bottom-right-radius: 7px;
}

.css-1e6jli8-MuiDrawer-docked {
  width: 158px !important;
}

.css-1e6jli8-MuiDrawer-docked .MuiDrawer-paper {
  width: 158px !important;
}

.calendar-ui .air-datepicker.-inline- div {
  font-size: 16px;
  font-family: var(--font-regular) !important;
  line-height: 17px !important;
}

.calendar-ui .air-datepicker-cell.-selected- {
  background-color: #22356d !important;
}

.calendar-ui .air-datepicker-cell.-in-range- {
  background-color: rgba(136, 154, 182, 0.15);
}

.calendar-ui .air-datepicker-cell.-current- {
  color: unset;
  background-image: linear-gradient(
      0deg,
      rgba(136, 154, 182, 0.15) 0%,
      rgba(136, 154, 182, 0.15) 100%
    ),
    #fff;
}

#customer-table table tr td:last-child {
  padding-right: 20px;
}

#customer-table table tr td.different-width {
  width: 13%;
}

#customer-table table tr td:not(:first-child).different-widthCustom {
  width: 7%;
}

/* #customer-table table tr td.different-width {
    width: 16.6%; 
} */

.bordertdforcustomer {
  border-bottom: 1px solid #22356d26;
  border-top: 1px solid #22356d26;
  border-width: 102%;
}

.shadowrowcustomer {
  border-radius: 7px;
  border: 2px solid rgba(136, 154, 182, 0.15);
  background: rgba(136, 154, 182, 0.15);
}

.shadowaccordingly {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset !important;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background: "linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)";
}
.css-wf16b5 {
  background: "linear-gradient(0deg, rgba(136, 154, 182, 0.15), rgba(136, 154, 182, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)";
}

.bordertdforcustomer.firstandlast {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-left: 1px solid #22356d26;
}
.bordertdforcustomer.last {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-right: 1px solid #22356d26;
}
.customforrowcustomer {
  border-bottom: 1px solid #22356d26 !important;
  margin: 2px 0;
}

.booking-tbody > tr > td:first-child {
  width: 200px !important;
  padding: 0px 20px;
}

.booking-tbody > tr > td:first-child {
  width: 14% !important;
}
/* Default styles for larger screens */
.booking-tbody td {
  display: table-cell;
}
.css-heg063-MuiTabs-flexContainer {
  display: flex;
  flex-wrap: wrap;
}
.css-k008qs {
  display: flex;
  flex-wrap: wrap;
}

/* Mobile view styles */
@media (max-width: 600px) {
  .clubmax-w-28 {
    width: 140px;
  }

  #customer-table table tr td.different-width {
    width: 50%;
  }

  /* .recharts-responsive-container{
        width: 100%;
    } */
  .booking-tbody td {
    display: none;
  }
  .booking-tbody .booking-date,
  .booking-tbody .customer {
    display: table-cell;
  }
}
